import { render, staticRenderFns } from "./ManageIncubators.vue?vue&type=template&id=fa0b3486&scoped=true"
import script from "./ManageIncubators.vue?vue&type=script&lang=js"
export * from "./ManageIncubators.vue?vue&type=script&lang=js"
import style0 from "./ManageIncubators.vue?vue&type=style&index=0&id=fa0b3486&prod&lang=scss"
import style1 from "./ManageIncubators.vue?vue&type=style&index=1&id=fa0b3486&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa0b3486",
  null
  
)

export default component.exports